import React, { useEffect, useState } from "react";
import "./MainPage.css";

import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import {
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import {
  PublicKey,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import ConfettiExplosion from "react-confetti-explosion";

import doge from "./Doge.png";
import Fronk from "./Fronk.png";
import bullet from "./Bullet.png";
import axios from "axios";
window.Buffer = window.Buffer || require("buffer").Buffer;

function MainPage() {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const [leaderboard, setLeaderboard] = useState<any[]>([]);
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false);
  const [isExploding, setIsExploding] = useState<boolean>(false);

  const handleSendToken = async () => {
    //1 Dolar in $BONK
    if (publicKey) {
      console.log(publicKey.toString());
      try {
        const tokenMint = new PublicKey(
          "5yxNbU8DgYJZNi3mPD9rs4XLh9ckXrhPjJ5VCujUWg5H"
        );
        const recipientAddress = new PublicKey(
          "BSh4WsfeZ2n4kT9oFfrGruYN4Nimi8oouQn2YmkjVvg1"
        );

        const transactionInstructions: TransactionInstruction[] = [];

        // Get the associated token account for the sender
        const senderTokenAccount = await getAssociatedTokenAddress(
          tokenMint,
          publicKey
        );

        // Get the associated token account for the recipient
        const recipientTokenAccount = await getAssociatedTokenAddress(
          tokenMint,
          recipientAddress
        );

        // If the recipient does not have an associated token account, create one for them
        if (!(await connection.getAccountInfo(recipientTokenAccount))) {
          transactionInstructions.push(
            createAssociatedTokenAccountInstruction(
              publicKey,
              recipientTokenAccount,
              recipientAddress,
              tokenMint
            )
          );
        }

        // Add the token transfer instruction to the transaction
        transactionInstructions.push(
          createTransferInstruction(
            senderTokenAccount, // source
            recipientTokenAccount, // dest
            publicKey, // owner
            100000000000 // transfer 100 token
          )
        );

        const transaction = new Transaction().add(...transactionInstructions);
        const signature = await sendTransaction(transaction, connection);
        console.log("Transaction successful! Signature:", signature);

        console.log("AMK");
        incrementClickAmount(publicKey.toString());

        setTimeout(() => {
          setIsDisplayed(true);
        }, 1000);

        setTimeout(() => {
          setIsExploding(true);
        }, 2000);

        //Wallet address is = publickKey.toString()
        // console.log("Last code", publicKey.toString());
      } catch (error) {
        //FAILED Transaction

        console.log(error);
        setIsDisplayed(false);
        setIsExploding(false);
      }
    }
    setIsDisplayed(false);
    setIsExploding(false);
  };

  useEffect(() => {
    if (publicKey) {
      console.log("Working");
      axios
        .post(
          "https://fronkbangbackend-production.up.railway.app/user/createUser",
          {
            walletAddress: publicKey?.toString(),
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [publicKey]);

  useEffect(() => {
    axios
      .get("https://fronkbangbackend-production.up.railway.app/user/getUsers")
      .then((response) => {
        console.log(response.data);
        setLeaderboard(response.data);
      });
  }, []);
  useEffect(() => {
    axios
      .get("https://fronkbangbackend-production.up.railway.app/user/getUsers")
      .then((response) => {
        console.log(response.data);
        setLeaderboard(response.data);
      });
  }, [isDisplayed]);

  async function incrementClickAmount(walletAddress: String) {
    try {
      const response = await axios.put(
        "https://fronkbangbackend-production.up.railway.app/user/incrementClickAmount",
        {
          walletAddress,
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="MainPage">
      <div className="MainPage-Container">
        <div className="MainPage-Container-ConnectWallet">
          <WalletMultiButton />
          {publicKey && <WalletDisconnectButton />}
        </div>
        <div>
          <h1>There is no need to be unfair!</h1>
          <p>69% of the $FRONK used will be burned</p>
          <p>31% will be used to create more burning experiences!</p>
        </div>
        <div className="MainPage-Container-GameScreen">
          <div className="Doge">
            {isExploding && <ConfettiExplosion />}
            <img src={doge} alt="BONK" />
          </div>
          <img
            className={`bullet ${isDisplayed ? "displayed" : "hidden"}`}
            src={bullet}
            alt="BULLET"
          />
          <img src={Fronk} alt="FRONK" />
        </div>
        <div className="MainPage-Container-GameButtons">
          <div className="pixel">
            <p onClick={handleSendToken}> Pay 1.000.000 $FRONK</p>
          </div>
        </div>
        <div className="MainPage-Container-LastBangs">
          <table className="Leaderboard">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Wallet Address</th>
                <th>Amount Clicked</th>
                <th>Burned $FRONK</th>
              </tr>
              <div className="line"></div>
            </thead>
            <tbody>
              {leaderboard &&
                leaderboard.map((user) => (
                  <>
                    <tr>
                      <td>{leaderboard.indexOf(user) + 1}. </td>
                      <td>{user?.walletAddress.slice(0, 12)}...</td>
                      <td>{user?.clickAmount}</td>
                      <td>{user?.clickAmount * 1000000} $FRONK</td>
                    </tr>
                    <div className="line"></div>
                  </>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
